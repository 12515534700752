<template>
  <div>

    <loading-indicator v-if="loading" text="Loading devices.." />

    <div v-else class="container is-fluid" :style="{'padding-top': '40px'}">
      <div v-if="devices.length > 0">
        <div class="header level">
          <div class="level-left">
            <h3 class="is-size-4">Devices</h3>
          </div>
          <div class="level-right">
            <div class="level-item">
              <button
                class="add-new-button button is-pozetron-primary"
                @click.prevent="is_claim_device_modal_active = true"
              >
                <span>Claim a device</span>
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <nav  class="panel">
          <devices-list-item
            v-for="device in devices"
            :key="device.id"
            :data="device"
          />
        </nav>
      </div>
      <div v-else>
        <section class="section has-text-centered">
          You haven't provisioned any devices.

          <br/>
          <br/>

          <div class="buttons is-centered">
            <a
              href="https://shop.pozetron.com"
              target="_blank"
              class="button is-pozetron-primary"
              :style="{verticalAlign: 'baseline'}"
            >
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'shopping-cart']" />
              </span>
              <span>Shop for devices</span>
            </a>


            <a
              href="#"
              @click.prevent="is_claim_device_modal_active = true"
              :style="{verticalAlign: 'baseline'}"
              class="button is-pozetron-primary"
            >
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Claim a device</span>
            </a>
          </div>
        </section>
      </div>
    </div>

    <bulma-modal
      v-if="is_claim_device_modal_active"
      :is_active="true"
      @close="is_claim_device_modal_active = false"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">Claim a device</p>
        <button
          class="button is-white"
          @click.prevent="is_claim_device_modal_active = false"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'times']" />
          </span>
        </button>
      </header>

      <section class="modal-card-body" :style="{'overflow': 'initial'}">

        <claim-device-form
          @close="is_claim_device_modal_active = false"
          @claimed="a_device_was_claimed = true"
        />

      </section>
    </bulma-modal>

  </div>
</template>

<script>
import api_devices from './../../api/devices.js'
import BulmaModal from './../common/BulmaModal.vue'
import LoadingIndicator from './../common/LoadingIndicator.vue'
import ClaimDeviceForm from './../forms/ClaimDeviceForm.vue'
import { update_hash_table } from './../../utils/hash_table.js'
import { get_sso_url } from './../../utils/sso.js'

import DevicesListItem from './DevicesListItem.vue'


export default {
  name: 'devices-list-screen',
  components: {
    BulmaModal,
    LoadingIndicator,
    ClaimDeviceForm,
    DevicesListItem
  },
  data() {
    return {
      is_claim_device_modal_active: false,
      a_device_was_claimed: false,
      loading: true,
      devices: []
    }
  },
  methods: {
    loadData() {
      this.loading = true
      return this.$userCache.initialize()
        .then(() => api_devices.get_list())
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.devices = response.data.slice().reverse()
            return Promise.resolve(response)
          } else {
            return Promise.reject({response})
          }
        })
        .catch((error) => {
          this.loading = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  },
  watch: {
    devices: function(value) {
      update_hash_table(value.map(device => device.id))
    },
    is_claim_device_modal_active: function(value) {
      if (!value && this.a_device_was_claimed) {
        this.loadData()
        this.a_device_was_claimed = false
      }
    }
  },
  mounted: function() {
    this.loadData()
  },
}
</script>
