import Vue from 'vue';
import _ from 'lodash'

//import config from './../../app.config.json'
import api_cache from './../api/cache'

const UserCache = Vue.observable({

  $tags: {

    _tags_map: new Map(),

    _initialize_from_server_response : function(response) {
      this._tags_map = response.data?.user?.cache?.tags?.length
        ?this._tags_map = new Map(response.data.user.cache.tags.map(obj => [obj.hash, obj.tag]))
        :this._tags_map = new Map()
    },

    _get_server_compatible_data : function() {
      return [...this._tags_map.entries()].map(entry => ({hash: entry[0], tag: entry[1]}))
    },

    get_tag : function(hash) {
      return this._tags_map.get(hash)
    },
    set_tag : function(hash, tag) {
      if (tag) {
        this._tags_map.set(hash, tag)
      } else {
        this._tags_map.delete(hash)
      }
    },

  },

  $versions: {

    _versions_map: new Map(),

    _initialize_from_server_response : function(response) {
      this._versions_map = response.data?.user?.cache?.versions?.length
        ?this._versions_map = new Map(response.data.user.cache.versions.map(obj => [obj.child, obj.parent]))
        :this._versions_map = new Map()
    },

    _get_server_compatible_data : function() {
      return [...this._versions_map.entries()].map(entry => ({child: entry[0], parent: entry[1]}))
    },

    get_parent : function(id) {
      return this._versions_map.get(id)
    },
    get_children : function(id) {
      return [...this._versions_map.entries()].filter(item => item[1] === id).map(item => item[0])
    },
    get_leaves : function(objects_in_asc_order) {
      const objects_with_parent_field = objects_in_asc_order
        .map(ob => ({
          ...ob,
          parent: this.get_parent(ob.id)
        }))

      const objects_grouped_by_parent = _.groupBy(objects_with_parent_field, function(ob) {
        return ob.parent?ob.parent:ob.id
      })

      const leaves = Object.keys(objects_grouped_by_parent)
        .map((key) => objects_grouped_by_parent[key])
        .map((objects_serie) => objects_serie[objects_serie.length - 1])

      return leaves
    },
    is_a_parent : function(id) {
      return !this.get_parent(id)
    },
    get_versions_of : function(id) {
      let parent = this.get_parent(id)
      parent = parent?parent:id
      return [
        ...[parent],
        ...this.get_children(parent)
      ]
    },
    set_parent : function(child_id, parent_id) {
      if (parent_id) {
        this._versions_map.set(child_id, parent_id)
      } else {
        this._versions_map.delete(child_id)
      }
    },
    remove_version : function (id) {
      const parent = this.get_parent(id)
      const children = this.get_children(id)

      children.forEach((child, index) => {
        if (index === 0){
          this.set_parent(child, null)
        } else {
          this.set_parent(child, children[0])
        }
      })

      if (parent) {
        this.set_parent(id, null)
      }
    }
  }
})

UserCache.install = function(Vue){
  Object.defineProperty(Vue.prototype, '$userCache', {
    get () { return UserCache }
  })
}

UserCache.initialize = function() {
  return api_cache.get()
    .then((response) => {
      if (response.status == 200) {
        this.$tags._initialize_from_server_response(response)
        this.$versions._initialize_from_server_response(response)
        return Promise.resolve(response)
      }
      return Promise.reject({response})
    })
}

UserCache.update = function({tags = false, versions = false}) {

  return api_cache.update_cache({
    ...tags
      ?{tags: this.$tags._get_server_compatible_data()}
      :{},
    ...versions
      ?{versions: this.$versions._get_server_compatible_data()}
      :{}
  })
    .then((response) => {
      if (response.status == 204) {
        return Promise.resolve()
      }
      return Promise.reject({response})
    })
}


export default UserCache;
