<template>
  <div :id="editorId" style="width: 100%; height: 20rem;"></div>
</template>

<script>
/* istanbul ignore file */

import ace from 'ace-builds'
import 'ace-builds/webpack-resolver'

export default {
  name: 'ace-editor',
  props: ['editorId', 'value', 'lang', 'theme'],
  data() {
    return {
      editor: Object,
      beforeContent: '',
    }
  },
  watch: {
    value(value) {
      if (this.beforeContent !== value) {
        this.editor.setValue(value, 1)
      }
    },
  },
  mounted() {
    const lang = this.lang || 'text'
    const theme = this.theme || 'github'

    this.editor = ace.edit(this.editorId)
    this.editor.setValue(this.value, 1)

    // mode-xxx.js or theme-xxx.js
    this.editor.getSession().setMode(`ace/mode/${lang}`)
    this.editor.setTheme(`ace/theme/${theme}`)

    this.editor.on('change', () => {
      this.beforeContent = this.editor.getValue()
      this.$emit('input', this.editor.getValue())
    })
  },
}
</script>
