import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import VueClipboard from 'vue-clipboard2'
import VueSimpleAlert from 'vue-simple-alert'
import Multiselect from 'vue-multiselect'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTimes, faCopy, faPlus, faShoppingCart, faEdit, faPen, faHistory, faCaretUp, faCaretDown,
  faEllipsisV, faDownload, faFileCode, faMicrochip, faLink
} from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPython } from '@fortawesome/free-brands-svg-icons'

import config from './../app.config.json'
import App from './components/App.vue'
import routes from './routes.js'
import UserCache from './plugins/cache.js'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import './styles/main.scss'


Vue.config.productionTip = false

if (config.SENTRY_API_URL) {
  Raven
    .config(`${config.SENTRY_API_URL}`, {
      environment: process.env.NODE_ENV
    })
    .addPlugin(RavenVue, Vue)
    .install()
}

// import any used font awesome 5 icons here.
library.add(
  faTimes, faCopy, faShoppingCart, faPlus, faEdit, faPen, faHistory, faPython,
  faTrashAlt, faCaretUp, faCaretDown, faEllipsisV, faDownload, faFileCode, faMicrochip, faLink
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('multiselect', Multiselect)

Vue.use(VueRouter)
Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
})
Vue.use(VueClipboard)
Vue.use(VueSimpleAlert)
Vue.use(UserCache)

const router = new VueRouter({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
