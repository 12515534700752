<template>
  <div>
    <div class="message is-light">
      <div class="message-body">
        Get <strong>Pozetron-ready</strong> firmware for your device. The firmware will have the credentials for communicating with the Pozetron cloud embedded right in.
      </div>
    </div>

    <form>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Device type</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div :class="['select', {'is-danger': $v.value.type.$error}]">
                <select
                  v-model="$v.value.type.$model"
                >
                  <option value="esp8266">ESP8266</option>
                  <option value="esp32">ESP32</option>
                  <option value="tinypico">TinyPICO</option>
                  <option value="esp32_c3">ESP32 C3</option>
                  <option value="esp32_c3_usb">ESP32 C3 USB</option>
                  <option value="esp32_s2">ESP32 S2</option>
                  <option value="esp32_s3">ESP32 S3</option>
                </select>
              </div>
              <div v-if="$v.value.type.$error">
                <p class="help is-danger" v-if="!$v.value.type.required">Field is required.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Release</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div :class="['select', {'is-danger': $v.value.release.$error}]">
                <select
                  v-model="$v.value.release.$model"
                >
                  <option value="stable">Stable</option>
                  <option value="unstable">Unstable</option>
                </select>
              </div>
              <div v-if="$v.value.release.$error">
                <p class="help is-danger" v-if="!$v.value.release.required">Field is required.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <button
              :class="['forward-button button is-pozetron-primary has-tooltip-right', {'is-loading': submitting}]"
              :disabled="$v['value'].$invalid"
              :data-tooltip="$v['value'].$invalid?'Please ensure all fields are valid':null"
              @click.prevent="handleSubmit"
            >
              Get firmware
            </button>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">

        </div>
      </div>

      <div v-if="response" class="message is-success">
        <div class="message-body">
          <p>
            Your firmware is ready.
            <br/>
            If the download did not start immediately, please, <a :href="response.data.url" :download="response.data.firmware_filename">click here <small class="has-text-gray ">(<strong>{{response.data.firmware_filename}}</strong>)</small></a> to start it manually.
          </p>
        </div>
      </div>
      <div v-if="response" class="message is-info">
        <div class="message-body">
          <p>
            TIP:  You can use <a href="https://esptool.pozetron.com">https://esptool.pozetron.com</a> to program your device from this browser.
          </p>
        </div>
      </div>

    </form>

  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import api_devices from './../../api/devices.js'
import { get_sso_url } from './../../utils/sso.js'


export default {
  name: 'devices-single-screen-firmware',
  mixins: [validationMixin],
  props: {
    device_id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      value: {
        type: 'esp32',
        release: 'stable'
      },
      submitting: false,
      response: null
    }
  },
  watch: {
    '$v.value.type.$model': function() {
      this.response = null
    },
    '$v.value.release.$model': function() {
      this.response = null
    },
  },
  validations: {
    value: {
      type: {
        required,
        mustBe: (value) => (['esp8266', 'esp32', 'tinypico', 'esp32_c3', 'esp32_c3_usb', 'esp32_s2', 'esp32_s3'].indexOf(value) >= 0)
      },
      release: {
        required,
        mustBe: (value) => (['stable', 'unstable'].indexOf(value) >= 0)
      }
    }
  },
  methods: {
    handleSubmit() {
      this.submitting = true
      this.response = false

      return api_devices.get_firmware(this.device_id, this.$v.value.$model)
        .then(response => {
          if (response.status == 200) {
            this.response = response
            this.submitting = false

            saveAs(response.data.url, response.data.firmware_filename)

            return Promise.resolve(response)

          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
  }
}
</script>
