<template>
  <div>

    <loading-indicator v-if="loading" text="Loading.." />

    <div v-else class="container is-fluid" :style="{'padding-top': '40px'}">

      <div
        class="box"
        @dragover.prevent="isDnDModalActive = true"
        @dragenter.prevent="isDnDModalActive = true"
      >

        <div class="header level">
          <div class="level-left">
            <router-link to="/modules" class="level-item">
              <span class="icon is-small">
                <i class="fas fa-arrow-left"></i>
              </span>
            </router-link>
            <div class="level-item">
              <modules-field
                placeholder="Select version"
                loading_text="Loading versions.."
                :multiple="false"
                :value="data.id"
                :versions_of="data.id"
                selectLabel=""
                deselectLabel=""
                :allowEmpty="false"
                :reverse="true"
                @input="$router.push({name: 'modules_single', params: {script_id: $event}})"
              />
            </div>
          </div>



          <div class="level-right">
            <div class="level-item">
              <div class="buttons">
                <button
                  :class="['forward-button button is-light has-tooltip-right is-small', {'is-loading': submitting}]"
                  :disabled="$v['value'].$invalid || !content_changed"
                  :data-tooltip="$v['value'].$invalid?'Please ensure all fields are valid':null"
                  @click.prevent="handleSave"
                >
                  Save changes
                </button>
                <button
                  v-if="devices.length"
                  :class="['forward-button button is-light has-tooltip-right is-small', {'is-loading': submitting}]"
                  :disabled="$v['value'].$invalid || !content_changed"
                  :data-tooltip="$v['value'].$invalid?'Please ensure all fields are valid':null"
                  @click.prevent="handleSave({redeploy: 'current_version'})"
                >
                  Save changes and redeploy current version
                </button>
              </div>
            </div>

            <div class="level-item">
              <a
                :href="data.url"
                target="_blank"
                :class="['button is-small is-light', {'is-disabled': undeploying}]"
              >
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'download']" />
                </span>
                <span>Download</span>
              </a>
            </div>
            <div class="level-item">
              <a
                href="#"
                :class="['button is-small is-danger', {'is-disabled': undeploying}]"
                @click.prevent="deleteModule()"
              >
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </span>
                <span>Delete</span>
              </a>
            </div>
          </div>
        </div>


        <div
          :style="{marginBottom: '2rem'}"
        >

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label has-text-left">Module name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input
                    v-model="$v.value.module.name.$model"
                    type="text"
                    placeholder="Module name"
                    :class="['input', {'is-danger': $v.value.module.name.$error}]"
                  >
                  <div v-if="$v.value.module.name.$error">
                    <p class="help is-danger" v-if="!$v.value.module.name.required">Field is required.</p>
                    <p class="help is-danger" v-if="!$v.value.module.name.maxLength">Must not contain more than {{ $v.value.module.name.$params.maxLength.max }} characters.</p>
                    <p class="help is-danger" v-if="!$v.value.module.name.allowedRegex">Only letters, numbers, "-" and "_" are allowed.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label has-text-left">Content</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <div class="level" :style="{marginBottom: '0.5rem'}">
                    <!-- Left side -->
                    <div class="level-left">
                      <div class="level-item">
                        <div class="buttons">
                          <button class="button is-small is-light" @click.prevent="$refs.file.click()">Load from file</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <input type="file" ref="file" :style="{display: 'none'}" @change="onFileSelect" />
                  <ace-editor
                    editor-id="main-editor"
                    v-model="$v.value.module.content.$model"
                    lang="python"
                  />
                  <div v-if="$v.value.module.content.$error">
                    <p class="help is-danger" v-if="!$v.value.module.content.required">Field is required.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div
        :style="{marginBottom: '2rem'}"
      >
        <hr />

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">Deployed to</label>
          </div>
          <div class="field-body" :style="{display: 'initial'}">
            <div class="buttons">
              <a
                href="#"
                @click.prevent="is_deploy_module_modal_active = true"
                class="button is-light is-small"
                :disabled="content_changed"
                :style="{verticalAlign: 'baseline'}"
              >
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <!-- TODO: prevent deploying two versions of the same script to the same device-->
                <span>Deploy to device</span>
              </a>
            </div>

            <nav v-if="devices.length > 0" class="panel">
              <devices-list-item
                v-for="device in devices"
                :key="device.id"
                :data="device"
                :context_script_id="script_id"
                @deleted="
                  devices = devices.filter(x => device.id !== x.id);
                "
              />
            </nav>
            <div v-else>
              This version of the module is not currently deployed to any device.
            </div>
          </div>
        </div>
      </div>


      <b-modal
        v-model="isDnDModalActive"
        :can-cancel="['escape', 'outside']"
        full-screen
      >
        <div
          @dragover.prevent
          @dragenter.prevent
          @drop.prevent="onDrop"
          :style="{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff'
          }"
        >
          <span class="icon is-large">
            <font-awesome-icon size="2x" :icon="['fas', 'file-code']" />
          </span>
          <span>Drop a file here to prefill your module.</span>
        </div>
      </b-modal>

      <bulma-modal
        v-if="is_deploy_module_modal_active"
        :is_active="true"
        @close="is_deploy_module_modal_active = false"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Deploy module</p>
          <button
            class="button is-white"
            @click.prevent="is_deploy_module_modal_active = false"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'times']" />
            </span>
          </button>
        </header>

        <section class="modal-card-body" :style="{'overflow': 'initial'}">

          <deploy-module-form
            :context_script_id="script_id"
            :hide_devices="devices.map(device => device.id)"
            @close="is_deploy_module_modal_active = false"
            @submitted="is_deploy_module_modal_active = false; loadData()"
          />

        </section>
      </bulma-modal>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'

import { validationMixin } from 'vuelidate'
import { required, maxLength, helpers } from 'vuelidate/lib/validators'

import api_scripts from './../../../api/scripts.js'
import api_devices from './../../../api/devices.js'
import { get_sso_url } from './../../../utils/sso.js'

import LoadingIndicator from './../../common/LoadingIndicator.vue'
import AceEditor from './../../common/AceEditor.vue'
import ModulesField from './../../common/ModulesField.vue'
import BulmaModal from './../../common/BulmaModal.vue'

import DeployModuleForm from './DeployModuleForm.vue'
import DevicesListItem from './../../screens/DevicesListItem.vue'

function initialState (){
  return {
    loading: true,
    undeploying: false,
    undeploying_failed: false,
    data: {},
    devices: [],

    value: {
      module: {
        name: '',
        content: '',
      }
    },
    isDnDModalActive: false,
    is_deploy_module_modal_active: false,
    submitting: false
  }
}

export default {
  name: 'modules-single-screen',
  mixins: [validationMixin],
  components: {
    LoadingIndicator,
    AceEditor,
    ModulesField,
    BulmaModal,
    DeployModuleForm,
    DevicesListItem
  },
  data() {
    return initialState()
  },
  props: {
    script_id: {
      type: String,
      required: true
    }
  },
  computed: {
    content_changed: function () {
      return (this.data.content !== this.$v.value.module.content.$model || this.data.name !== this.$v.value.module.name.$model)
    }
  },
  watch: {
    data: {
      handler: function(data) {
        if (data.name !== undefined) {
          this.value.module.name = data.name
        }
        if (data.content !== undefined) {
          this.value.module.content = data.content
        }
      },
      immediate: true,
      deep: true
    },
  },
  validations: {
    value: {
      module: {
        name: {
          required,
          maxLength: maxLength(255),
          allowedRegex: helpers.regex('allowedRegex', /^([a-zA-Z0-9]|.|-|_)*$/) // i.e. alpha-numerics, dots, underscore and hyphen
        },
        content: {
          required
        }
      }
    }
  },
  methods: {
    get_single() {
      return api_scripts.get_single(this.script_id, true)
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.data = {
              ...response.data,
              ...{content: atob(response.data.content_)} // NOTE: notice how we read from `content_` but later POST into `content`
            }
            return Promise.resolve()
          }
          return Promise.reject({response})
        })
    },
    get_devices() {
      return api_devices.get_list({script_id: this.script_id})
        .then((response) => {
          this.loading = false
          this.devices = response.data
          return Promise.resolve()
        })
    },
    loadData() {
      // Continue with data fetch
      this.loading = true
      return this.get_single()
        .then(() => this.get_devices())
        .catch((error) => {
          this.loading = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    deleteModule() {
      return this.$confirm(
        'Deleting this module will also remove it from any device where it is currently deployed. Are you sure?',
        null,
        'warning',
        {
          confirmButtonText: 'Yes, delete now'
        }
      )
        .then(
          () => {
            this.undeploying = true
            this.undeploying_failed = false
            return api_scripts.delete(this.data.id)
              .then(response => {
                if (response.status == 204) {

                  // After deleting the script version, remove its occurences in local versions' cache
                  this.$userCache.$versions.remove_version(this.data.id)

                  // sync cache
                  return this.$userCache.update({versions: true})
                    .catch(() => {
                      /* istanbul ignore next */
                      return this.$alert('Script deleted successfully, but an error occured when updating its versioning data.')
                      .then(() => Promise.reject({response}))
                    })
                    .then(() => this.$userCache.initialize())
                    .then(() => Promise.resolve(response))

                } else {
                  return Promise.reject({response})
                }
              })
              .then(() => {
                this.undeploying = false
                this.$router.push({name: 'modules_list'})
                return Promise.resolve()
              })
              .catch(error => {
                this.undeploying = false
                this.undeploying_failed = true
                if (error.response && error.response.status == 401) {
                  // Redirect to single sign-on url
                  window.location.assign(get_sso_url())
                } else {
                  alert('Something went wrong.')
                  // re-raise
                  return Promise.reject(error)
                }
              })
          },
          () => {
            // do nothing on cancel
            return Promise.resolve()
          }
        )
    },
    onDrop(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isDnDModalActive = false
      if (event.dataTransfer.files.length === 1) {
        this.loadFile(event.dataTransfer.files[0])
      }
    },
    onFileSelect(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isDnDModalActive = false
      this.loadFile(event.target.files[0])
    },
    async loadFile(file) {
      this.$v.value.module.name.$model = file.name
      this.$v.value.module.content.$model = await file.text()
    },
    handleSave({redeploy = null}) {
      this.submitting = true

      return api_scripts.create(this.$v.value.module.$model)
        .then(response => {
          if (response.status == 200) {

            // After creating the new script version, update its parent in local cache
            const parent_id = this.$userCache.$versions.get_parent(this.data.id)
            this.$userCache.$versions.set_parent(response.data.id, parent_id?parent_id:this.data.id)

            // sync cache
            return this.$userCache.update({versions: true})
              .catch(() => {
                /* istanbul ignore next */
                return this.$alert('A new script was created successfully, but an error occured while trying to associate it as a version of the edited script.')
                .then(() => Promise.reject({response}))
              })
              .then(() => this.$userCache.initialize())
              .then(() => Promise.resolve(response))
          }
          return Promise.reject(response)
        })
        .then(response => {
          if (redeploy === 'current_version') {
            return this.handleRedeployCurrentVersion(response.data.id).then(() => {
              return Promise.resolve(response)
            })
          }

          return Promise.resolve(response)
        })
        .then((response) => {
          this.submitting = false
          this.$router.push({name: 'modules_single', params: {script_id: response.data.id}})
          return Promise.resolve()
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    handleRedeployCurrentVersion(new_script_id) {
      return Promise.all(
        [
          ...this.devices.map((device) => (
            api_devices.delete_deployed_module(device.id, this.script_id)
          )),
          ...this.devices.map((device) => (
            api_devices.deploy_module(device.id, new_script_id)
          ))
        ]
      )
    }
  },

  mounted: function() {
    this.loadData()
  },

  beforeRouteLeave(to, from, next) {
    if (this.content_changed) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },

  beforeRouteUpdate(to, from, next) {
    // On navigating from the same route, reload data, in case route params changed
    next()
    Vue.nextTick(() => {
      Object.assign(this.$data, initialState());
      this.loadData()
    })
  },
}
</script>

<style>
.navbar-burger {
  color: #fff;

  /* Fix burger button height to expand as navbar height expands for any reason */
  height: auto;
}
.navbar-burger:hover {
  color: #fff;
}
</style>
