<template>
  <div>
    <button
      id="disconnectButton"
      v-if="this.serial"
      data-tooltip="After clicking disconnect unplug the device"
      class="button mx-5 is-light has-tooltip-right"
      @click.prevent="serial_disconnect"
    >Disconnect</button>
    <button
      v-else
      id="connectButton"
      data-tooltip="See the console of a locally attached ESP device"
      class="button mx-5 is-light has-tooltip-right"
      @click.prevent="serial_connect"
    >Local</button>
    <button
      v-if="this.ws && this.remoteConsole"
      class="button mx-5 is-light"
      @click.prevent="ws_disconnect"
    >Disconnect</button>
    <button
      v-else-if="this.remoteConsole"
      class="button mx-5 is-light"
      @click.prevent="ws_connect"
    >Remote</button>
    <hr />

    <p>
      Status: <span v-if="!ws && !serial">Not connected</span>
      <span v-else-if="serial">Connected to {{chip}}</span>
      <span v-else-if="!is_device_connected && is_awaiting_device_first_connection">Awaiting device connection</span>
      <span v-else-if="!is_device_connected && !is_awaiting_device_first_connection">Lost connection with the device, awaiting reconnection</span>
      <span v-else-if="is_device_connected">Connected</span>
    </p>

    <div id="terminal"></div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { Terminal } from 'xterm';
import { ESPLoader, Transport } from './../../../vendor/esptool-js/'
import * as configCat from 'configcat-js'


import config from './../../../app.config.json'
import api_replsessions from './../../api/replsessions'
import { get_sso_url } from './../../utils/sso.js'


export default {
  name: 'devices-single-screen-repl',
  props: {
    device_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ws: null,
      serial: null,
      chip: null,
      serial_device: null,
      transport: null,
      term: null,
      remoteConsole: null,
      is_device_connected: false,
      is_awaiting_device_first_connection: true,
    }
  },
  methods: {
    // Get feature status from ConfigCat
		async getremoteConsoleFeatureFlag() {
			try {
				let configCatClient = configCat.createClient(
					process.env.VUE_APP_CONFIGCAT_SDK
				);
				const res = await configCatClient.getValueAsync(
					'remoteConsole',
					false
				);
				this.remoteConsole = res
			} catch (err) {
				this.error = err.message
			}
			this.loading = false;
		},
    async serial_connect() {
      if (this.serial_device === null && this.term) {
        this.serial_device = await navigator.serial.requestPort({}).catch(function() {
          this.term.writeln('Unable to connect to serial ports. Check your OS permissions.')
        });
        let transport = this.transport;
        try {
          this.transport = new Transport(this.serial_device);
        } catch (e) {
          console.log(e);
          this.transport = transport;
        }
      }
      let options = {transport: this.transport, baudrate: 921600, terminal: this.term};
      let esploader = new ESPLoader(options);
      this.chip = await esploader.main_fn();

      if (this.chip.CHIP_NAME != 'ESP8266') {
        await esploader.hard_reset();
      } else {
        await esploader.soft_reset();
      }

      // Reconnect to the device to show the user program output
      await this.transport.disconnect();
      await this.transport.connect();

      this.serial = true;


      while (this.serial) {
        let val = await this.transport.rawRead();
        if (typeof val !== 'undefined') {
          this.term.write(val);
        } else {
          break;
        }
      }
    },
    async serial_disconnect() {
      this.term.writeln('Disconnect your device now.')
      this.serial = null;
      if (this.serial_device) {
        try {
          await this.transport.disconnect();
          await this.serial_device.close();
          await this.term.close();
        } catch (e) {
          console.log(e);
        }
      }
    },
    ws_connect() {
      this.is_device_connected = false
      this.is_awaiting_device_first_connection = true

      this.term.write('Creating a new Console session..\r\n')

      return api_replsessions.create({device_id: this.device_id})
        .then((response) => {
          this.loading = false
          if (response.status == 201) {
            this.term.write('New Console session created, successfully..\r\n')

            this.term.write('Connecting to Console..\r\n')

            // TODO better cookie params
            // https://stackoverflow.com/questions/1612177/are-http-cookies-port-specific#comment23384819_1613762
            Cookies.set(config.REPL_SESSION_TOKEN_COOKIE_NAME, response.data.user_token, {
              expires: 7,
              sameSite: 'None',
              secure: false
            })

            this.ws = new WebSocket(`${config.REPL_SERVICE_BASE_URL}/ws/user/${response.data.id}`);

            this.ws.onopen = () => {
              this.onDataIDisposable = this.term.onData((data) => {
                // Pasted data from clipboard will likely contain
                // LF as EOL chars.
                data = data.replace(/\n/g, '\r');

                this.ws.send(data);
              });

              this.term.focus();
              this.term.element.focus();
              this.term.write('\x1B[32m' + 'Welcome to the Pozetron Console!' + '\x1b[m' + '\r\n');
              this.term.write('Awaiting device connection..' + '\r\n');
            }

            this.ws.onmessage = (e) => {
              if (e.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                  const message = JSON.parse(reader.result)
                  switch (message.type) {
                    case 'POZ_CONNECTED':
                      if (!this.is_device_connected) {
                        this.is_device_connected = true
                        if (this.is_awaiting_device_first_connection) {
                          this.is_awaiting_device_first_connection = false
                          this.term.write('\x1B[32m' + 'Device connected and ready.\n\r' + '\x1b[m')
                        }
                      }
                      break;
                    case 'POZ_LOST_CONNECTION':
                      this.is_device_connected = false
                      break;
                    case 'POZ_CLOSED_INDEFINITELY':
                      this.term.write('Device closed the connection.\n\r')
                      this.is_device_connected = false
                      break;

                  }
                }
                reader.readAsText(e.data)
              } else {
                this.term.write(e.data)
              }
            }

            this.ws.onclose = () => {
              this.term.write('\r\n' + '\x1B[32m' + 'Console Disconnected.' + '\x1b[m' + '\r\n');
              if (this.onDataIDisposable) {
                this.onDataIDisposable.dispose()
              }
              this.ws = null
            }

            return Promise.resolve(response)
          }
          return Promise.reject({response})
        })
        .catch((error) => {
          this.loading = false
          this.term.write('\x1B[32m' + 'Could not create the Console session.' + '\x1b[m' + '\r\n')
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            this.term.write('\x1B[32m' + 'Something went wrong.' + '\x1b[m' + '\r\n')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    ws_disconnect() {
      if (this?.ws?.close) {
        this.ws.send(new Blob([JSON.stringify({type: 'POZ_CLOSED_INDEFINITELY'})], {type : 'application/json'}));
        this.term.write('\r\n' + 'Sent disconnect request.' + '\r\n');

        // NOTE here we let the server close the websocket, hence the next line being commented out
        // this.ws.close()
      }
    }
  },
  mounted() {
    this.term = new Terminal({});
    this.term.open(document.getElementById('terminal'));
    this.remoteConsole = this.getremoteConsoleFeatureFlag();
  }
}
</script>

<style scoped>
#terminal {
  margin-bottom: 2rem;
}
</style>
